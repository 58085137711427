<template>
  <div class="about-container pc-wrap">
    <!-- 盒子1 -->
    <div class="wrapper-box wrapper-box-1">
      <img class="box-bg" :src="aboutBg" alt="" />

      <div class="wrapper-content">
        <div class="top-box">
          <div class="box">
            <div class="logo">
              <img :src="logoIcon" alt="" />
            </div>
            <div class="decoration-box">
              <div class="left fontBold">让开发项目变得更简单(*￣︶￣*)</div>
              <div class="right fontBold">GO</div>
            </div>
          </div>
        </div>
        <div class="introduce">
          <div>
            <p
              class="fontRegular"
              v-for="(text, index) in abutUsTips"
              :key="index"
            >
              {{ text }}
            </p>
          </div>
        </div>
        <div class="wrapp-group-box">
          <!-- 表单 -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>

    <fixed-customer-service></fixed-customer-service>
  </div>
</template>

<script setup>
import fixedCustomerService from "@/components/fixed-customer-service/index";
import { computed, ref, getCurrentInstance } from "vue";
import { abutUsTips } from "../data.js";

// 图标
// logo
const logoIcon = require("@/assets/images/logo-size-large.png");

// 图片
const aboutBg = require("@/assets/images/about/about-bg.png");

const { proxy } = getCurrentInstance();
</script>

<style lang="scss">
.about-container {
  &.pc-wrap {
    .wrapper-box {
      display: grid;
      grid-template-rows: auto 1fr auto;

      // 盒子1
      &-1 {
        // background-image: url(../../../assets/images/about/about-bg.png);
        // background-repeat: no-repeat;
        // background-attachment: inherit;
        // background-position: top;
        // background-size: 100% 100%;
        width: 100%;
        height: 100%;
        max-height: 5000px;
        position: relative;

        .box-bg {
          width: 100%;
          position: absolute;
          z-index: -1;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }

        .wrapper-content {
          width: 100%;
          max-width: 1200px;
          margin: auto;

          .top-box {
            padding: 177px 0 185px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .logo {
              width: 465px;
              height: 92px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .decoration-box {
              display: flex;
              margin-top: 20px;
              .left {
                width: 505px;
                height: 80px;
                background-color: #fff;
                border-radius: 10px 0px 0px 10px;
                color: #281e85;
                font-size: 30px;
                line-height: 80px;
                padding-left: 20px;
                font-weight: bold;
              }
              .right {
                width: 140px;
                height: 80px;
                background-color: #281e85;
                border-radius: 0px 10px 10px 0px;
                color: white;
                line-height: 80px;
                font-size: 50px;
                text-align: center;
              }
            }
          }

          .introduce {
            width: 100%;
            font-size: 18px;
            color: white;
            line-height: 32px;
            box-sizing: border-box;
            margin: 50px 0;
            div {
              width: 100%;
              max-width: 800px;
            }
            p {
              margin-bottom: 30px;
            }
          }

          .wrapp-group-box {
            margin: 70px auto;
            display: flex;
            justify-content: center;
            padding-top: 105px;

            .sub-title {
              color: white !important;
              font-size: 30px !important;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .customer-box {
      position: fixed;
      right: 30px;
      top: calc(50vh - 130px);
    }
  }
}
</style>
