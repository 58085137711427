<template>
  <div class="about-container mobile-wrap">
    <!-- 盒子1 -->
    <div class="wrapper-box wrapper-box-1">
      <div class="wrapper-content">
        <div class="top-box">
          <div class="logo">
            <img :src="logoLabelIcon" alt="" />
          </div>
          <div class="decoration-box">
            <div class="left fontBold">让开发项目变得更简单(*￣︶￣*)</div>
            <div class="right fontBold">GO</div>
          </div>
        </div>
        <div class="introduce">
          <div>
            <p
              class="fontRegular"
              v-for="(text, index) in abutUsTips"
              :key="index"
            >
              {{ text }}
            </p>
          </div>
        </div>
        <div class="wrapp-group-box">
          <!-- 表单 -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>

    <!-- 介绍列表 -->
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from "vue";
import { abutUsTips } from "../data.js";

// 图标
// logo
const logoLabelIcon = require("@/assets/images/logo-label-light.png");

const { proxy } = getCurrentInstance();
</script>

<style lang="scss">
.about-container {
  &.mobile-wrap {
    .wrapper-box {
      display: grid;
      grid-template-rows: auto 1fr auto;

      // 盒子1
      &-1 {
        background-image: url(../../../assets/images/about/mobile_banner.png);
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-position: -301px -40px;
        background-size: calc(100% + 500px) 976px;
        background-color: #4778f8;

        .top-box {
          box-sizing: border-box;
          margin-top: 70px;
          padding-left: 25px;

          .logo {
            width: 224px;
            height: auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .decoration-box {
            display: flex;
            margin-top: 20px;
            // padding-left: 15px;
            .left {
              background-color: #fff;
              color: #281e85;
              font-size: 13px;
              font-weight: bold;
              padding: 9px 37px 12px 11px;
              border-radius: 10px 0px 0px 10px;
              color: rgba(40, 30, 133, 1);
            }
            .right {
              background-color: #281e85;
              border-radius: 0px 10px 10px 0px;
              color: white;
              font-size: 23px;
              width: 67px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .introduce {
          font-size: 12px;
          line-height: 20px;
          padding: 20px;
          margin-bottom: 0;
          width: 100%;
          color: white;
          box-sizing: border-box;
          p {
            margin-bottom: 20px;
          }
        }

        .wrapp-group-box {
          background-image: url("../../../assets/images/case-buttom-bg-1.png");
          background-repeat: no-repeat;
          background-size: auto 330px;
          background-position: center;
        }
      }
    }
  }
}
</style>
