<template>
  <div class="customer-box">
    <div
      class="icon-item"
      :class="theme"
      v-for="(item, index) in customerBars"
      :key="'item' + index"
      @click="clickContact(index)"
    >
      <img :src="item.icon" alt="" />
      <div class="hide-detail" v-if="item.showContent">
        {{ item.showContent }}
      </div>
      <div class="hide-detail" v-if="item.qrocdeIcon">
        <img class="qrocdeIcon" :src="item.qrocdeIcon" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const emits = defineEmits(["contact"]);

const props = defineProps({
  type: {
    String,
    default: "light",
  },
});

const theme = ref(props.type);
watch(
  () => props.type,
  (nVal) => {
    theme.value = nVal;
  }
);

const customerBars = computed(() => [
  {
    icon: require(`@/assets/images/customer-icon/light/qq.png`),
    showContent: "cjx@cloudeffective.cn",
  },
  {
    icon: require(`@/assets/images/customer-icon/light/phone.png`),
    showContent: "177-2247-6866",
  },
  {
    icon: require(`@/assets/images/customer-icon/light/wx.png`),
    type: "image",
    qrocdeIcon: require("@/assets/images/call-qrocde.png"),
  },
]);

const clickContact = (index) => {
  if (index === customerBars.value.length - 1) {
    return;
  }

  emits("contact");
};
</script>

<style lang="scss" scoped>
.customer-box {
  display: grid;
  grid-template-rows: repeat(4, auto);
  align-items: center;
  z-index: 9;

  &.fixed {
    position: fixed;
    top: 35vh;
    right: 20px;
  }

  .icon-item {
    padding: 15px 0;
    width: 30px;
    height: 35px;
    position: relative;

    &.light {
      color: white;
      transition: 300ms;
    }
    &.dark {
      color: black;
    }

    &:hover .hide-detail {
      left: -70px;
      opacity: 1;

      .qrocdeIcon {
        width: 117px;
        height: 110px;
      }
    }

    &:nth-child(1),
    &:nth-child(2) {
      transition: 800ms;
      &:hover .hide-detail {
        left: -165px;
        opacity: 1;
      }

      .hide-detail {
        font-size: 20px;
        font-weight: bold;
        top: 20px;
        text-shadow: 0px -1px 1px #2196f3, 2px 1px 1px #03a9f4a6;
      }
    }
    &:nth-child(1) {
      &:hover .hide-detail {
        left: -225px;
        opacity: 1;
      }
    }

    > img {
      width: 100%;
      height: auto;
    }
    .hide-detail {
      width: fit-content;
      font-size: 15px;
      position: absolute;
      top: 30px;
      white-space: nowrap;
      z-index: 0;
      left: 0;
      opacity: 0;
      transition: left 300ms, opacity 100ms;

      .qrocdeIcon {
        position: inherit;
        left: -55px;
        top: -30px;
        width: 0;
        height: 0;
      }
    }
  }
}
</style>
